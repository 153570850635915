import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEventCallback } from '@mui/material';

import {
  EntityPromotionsResponse,
  PromotionComplete,
  PromotionDefunct,
  PromotionFeatures,
} from '@playq/octopus-common';

import { QUERY_PROMOTIONS_TIMEOUT, useQueryPromotions } from '/api/hooks/promotions';
import { activeSpaceToolkit } from '/store/toolkits';
import { getSandboxPromotion, isPromotionLoading } from '/shared/Promotions/helpers';
import { EntityID } from '/shared/Promotions/components/Service/types';

import { PromotionsPreview } from './PromotionsPreview';
import { IControlBarPromotionPreviewProps } from './types';
import { ControlBarWrapper, useControlBarPromotionPreviewStyles } from './styles';

const features = [PromotionFeatures.SpaceName];

export const ControlBarPromotionsPreview = <ID extends EntityID>({
  version,
  service,
  entityID,
  spaceless,
  disabled,
  onChange,
  setIsPromotedOnLive,
  lastAvailableVersion,
  ...props
}: IControlBarPromotionPreviewProps<ID>) => {
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const space = spaceless ? undefined : activeSpace;
  const defunct = 'defunct';

  const queryVersions = useMemo(() => service.queryPromotions.bind(service), [service]);
  const { data, refetch, isLoading } = useQueryPromotions(entityID, features, queryVersions);

  const [promotions, setPromotions] = useState<EntityPromotionsResponse | undefined>(data);
  const promotionTimerID = useRef<number | undefined>();

  const statuses = useMemo(() => {
    const result = { sandbox: false, live: false };

    if (!promotions) {
      return result;
    }

    const { live, sandbox } = promotions;

    const isLive = live?.state instanceof PromotionComplete;
    const isLiveDefunct = live?.state instanceof PromotionDefunct;
    const isSandboxDefunct = sandbox?.default_?.state instanceof PromotionDefunct;
    const isDefaultSandbox = sandbox?.default_?.state instanceof PromotionComplete;
    const currentSpace = sandbox?.custom?.find(({ id }) => id.serialize() === space?.id.serialize());
    const isCustomSandbox = currentSpace !== undefined;
    const isCustomSandboxDefunct =
      space !== undefined && isCustomSandbox ? currentSpace.promotion.state instanceof PromotionDefunct : false;

    const getSandboxStatus = () => {
      if (
        (isSandboxDefunct && !isCustomSandbox) ||
        (isSandboxDefunct && isCustomSandboxDefunct) ||
        (isCustomSandbox && isCustomSandboxDefunct)
      ) {
        return defunct;
      }

      return isDefaultSandbox || isCustomSandbox;
    };

    return {
      live: isLiveDefunct ? defunct : isLive,
      sandbox: getSandboxStatus(),
    };
  }, [space, promotions]);

  const classes = useControlBarPromotionPreviewStyles(statuses)();

  const isPromotedOnLive: boolean = useMemo(() => {
    const live = data?.live;
    if (!live || version === undefined) {
      return false;
    }
    if (!(live.state instanceof PromotionComplete)) {
      return false;
    }
    return live.version === version;
  }, [data?.live, version]);

  useEffect(() => {
    if (setIsPromotedOnLive) {
      setIsPromotedOnLive(isPromotedOnLive);
    }
    // passing isPromotedOnLive to deps can cause too many re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPromotedOnLive]);

  useEffect(() => {
    setPromotions(data);
  }, [data]);

  const handleChange = useEventCallback((newPromotions: EntityPromotionsResponse | undefined) => {
    setPromotions(newPromotions);
    onChange?.(newPromotions);
    const newLiveVersion = newPromotions?.live?.version;
    if (newLiveVersion !== undefined && newLiveVersion === version) {
      setIsPromotedOnLive?.(true);
    }
  });

  useEffect(() => {
    if (isPromotionLoading(promotions?.live) || isPromotionLoading(getSandboxPromotion(promotions, space))) {
      promotionTimerID.current = window.setTimeout(() => {
        refetch();
      }, QUERY_PROMOTIONS_TIMEOUT);
    }
    return () => {
      window.clearTimeout(promotionTimerID.current);
    };
  }, [promotions, refetch, space]);

  return (
    <ControlBarWrapper>
      <PromotionsPreview<ID>
        {...props}
        promotions={promotions}
        version={version}
        service={service}
        entityID={entityID}
        previewButtonProps={{ withVersion: true, className: classes.button }}
        disabled={disabled || isLoading}
        onChange={handleChange}
        lastAvailableVersion={lastAvailableVersion}
        spaceless={spaceless}
      />
    </ControlBarWrapper>
  );
};
