import styled from 'styled-components';

export const PromotionCompleteText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

export const DefunctContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-left: 5px;
    font-size: 18px;
  }
`;
