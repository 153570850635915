import styled from 'styled-components';
import { Badge, Button, DialogContent } from '@mui/material';

export const ButtonStyled = styled(Button)`
  min-width: 0;
  font-size: 15px;

  & svg {
    margin-right: ${(props) => props.theme.spacing(0.5)};
  }
`;

export const BadgeStyled = styled(Badge)<{ $away?: boolean }>`
  & .MuiBadge-dot {
    background-color: ${(props) => (props.$away ? props.theme.palette.warning.main : props.theme.palette.success.main)};
    left: 1px;
    bottom: 5px;
    height: 10px;
    width: 10px;
    border: 1.5px solid white;
    border-radius: 50%;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 350px;
`;

export const OnlineIndicator = styled.div<{ away?: boolean }>`
  background-color: ${(props) => (props.away ? props.theme.palette.grey[500] : props.theme.palette.success.main)};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
