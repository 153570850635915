import styled from 'styled-components';
import { Info as MuiInfoIcon } from '@mui/icons-material';

interface IColored {
  mainColor: string;
}

export const PromotionForm = styled.div`
  --main-color: ${(p: IColored) => p.mainColor};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 46px;

  .promote {
    width: 140px;
  }

  .demote {
    width: 46px;
    justify-self: flex-end;
  }

  .status {
    min-width: 150px;
    line-height: 1.5;
  }

  .status,
  .demote {
    margin-left: ${(props) => props.theme.spacing(2)};
  }

  .space {
    margin-left: ${(props) => props.theme.spacing(0.5)};
  }

  .spaceName {
    font-weight: bold;
  }
`;

export const InfoIcon = styled(MuiInfoIcon)`
  margin-left: ${(props) => props.theme.spacing()};
  font-size: 1rem;
`;
