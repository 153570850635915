import { GenericFailure } from '@playq/services-shared';
import {
  DevicesSortField,
  DevicesSort,
  AppDevice,
  DeviceID,
  DeviceIdentityDataStruct,
  Device,
  DeviceSerialized,
} from '@playq/octopus2-devices';

import { ICollectionTableQueryChange } from '/shared/Table';
import { IQuery } from '/common/models';

export enum IDeviceIcon {
  Unknown = 'UNKNOWN',
  Known = 'KNOWN',
  Cancel = 'CANCEL',
  Check = 'CHECK',
  Remove = 'REMOVE',
  Save = 'SAVE',
  Edit = 'EDIT',
  Subscribe = 'SUBSCRIBE',
  Unsubscribe = 'UNSUBSCRIBE',
}

export type DeviceQuery = IQuery<DevicesSortField, DevicesSort>;

export interface DeviceTableProps {
  queryDevices: {
    devices: AppDevice[];
    total: number;
    error: Error | GenericFailure | null;
    refetch: () => void;
  };
  initialQuery: DeviceQuery;
  onQueryChange: (qc: ICollectionTableQueryChange<DeviceQuery>) => void;
  subscribeDevice: (deviceID: string, device: Device) => void;
  changeSubscription: (deviceID: string, device: Device) => void;
  unbindDevice: (device: { device: DeviceID }) => void;
  updateDevice: (device: { device: DeviceID; data: DeviceIdentityDataStruct }) => void;
  unsubscribe: (value: boolean) => void;
}
export type StorageDevice = { deviceID: string; device: DeviceSerialized };
