import { FC } from 'react';
import styled from 'styled-components';
import { InfoOutlined as MuiInfoOutlinedIcon, Warning as MuiWarningIcon } from '@mui/icons-material';
import { orange, red } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Environment } from '@playq/octopus-common';

import { IThemeDependentStyledComponentProps } from '/common/models';
import { TextAnimation as SharedTextAnimation } from '/shared/TextAnimation';

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  &:last-child {
    margin-top: 3px;
  }

  &:first-child:last-child {
    margin: 0.5rem 0;
  }
`;

export const sandboxColor = orange[500];
export const liveColor = red[500];

const statusIconStyles = `margin-left: 5px;
  font-size: 19px;
`;

export const WarningIcon = styled(MuiWarningIcon)`
  ${statusIconStyles};
  margin-bottom: -3px;
`;

export const InfoIcon = styled(MuiInfoOutlinedIcon)`
  ${statusIconStyles};
  color: ${orange[700]};
`;

interface ITextAnimationStyled {
  env: Environment;
  text: string;
}

export const TextAnimation: FC<ITextAnimationStyled> = ({ env, text }) => (
  <SharedTextAnimation color={env === Environment.Sandbox ? sandboxColor : liveColor}>{text}</SharedTextAnimation>
);

export const PreviewButton = styled.button<IThemeDependentStyledComponentProps>`
  padding: 0;
  border: none;
  text-align: left;
  cursor: pointer;
  color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.text.primary};

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const EnvironmentsWrapper = styled.div`
  min-width: 550px;

  .env-form {
    .demote {
      margin-left: auto;
    }

    &:not(:last-of-type) {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }
`;

export const ErrorText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  margin: 0 auto 0 ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.error.main};
`;

export const promotionBadgeTheme: Theme = createTheme({
  palette: {
    primary: { main: sandboxColor },
    secondary: { main: liveColor },
  },
});

export const Wrapper = styled.div`
  width: fit-content;
`;

export const ControlBarWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing(1)};
`;

export const useControlBarPromotionPreviewStyles = (promotions: {
  sandbox: boolean | string;
  live: boolean | string;
}) =>
  makeStyles((theme: Theme) => {
    const isSandboxDefunct = promotions.sandbox === 'defunct';
    const isLiveDefunct = promotions.live === 'defunct';
    const sandboxTextColor = promotions.sandbox === true ? sandboxColor : theme.palette.text.secondary;
    const liveTextColor = promotions.live === true ? theme.palette.background.paper : theme.palette.text.secondary;
    const liveBackgroundColor = promotions.live === true ? liveColor : theme.palette.background.paper;
    const getFontWeight = (isPromoted: boolean | string) => (isPromoted === true ? 'bolder' : 'initial');
    const getBorder = (isPromoted: boolean | string) => (isPromoted === true ? `1px solid` : 'none');

    const commonStyles = {
      borderRadius: 5,
      padding: '2px 4px',
    };

    return {
      button: {
        rowGap: 3,
        '& > :first-child > span': {
          ...commonStyles,
          backgroundColor: theme.palette.background.paper,
          color: isSandboxDefunct ? theme.palette.error.main : sandboxTextColor,
          fontWeight: getFontWeight(promotions.sandbox),
          border: getBorder(promotions.sandbox),
        },
        '& > :last-child > span': {
          ...commonStyles,
          color: isLiveDefunct ? theme.palette.error.main : liveTextColor,
          backgroundColor: liveBackgroundColor,
          fontWeight: getFontWeight(promotions.live),
          border: getBorder(promotions.live),
        },
      },
    };
  });
