import { Button, Tooltip } from '@mui/material';
import { FC, memo } from 'react';

export const ButtonGroup: FC<{ refetch: () => void; tooltipText: string; unsubscribe: () => void; disabled: boolean }> =
  memo(({ refetch, tooltipText, unsubscribe, disabled }) => {
    return (
      <>
        <Button variant='outlined' onClick={refetch} color='primary'>
          Refresh
        </Button>
        <Tooltip title={tooltipText} placement='top'>
          <Button variant='contained' onClick={unsubscribe} color='primary' disabled={disabled}>
            unsubscribe
          </Button>
        </Tooltip>
      </>
    );
  });
