import styled from 'styled-components';

export const Dragger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.drawer};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 5px;
  cursor: col-resize;
  transition: ${(props) => props.theme.transitions.create('background-color')};

  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;
