import styled from 'styled-components';

const styledByDisable =
  (positive: string, negative: string) =>
  ({ disabled }: { disabled?: boolean }) =>
    disabled ? positive : negative;

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  padding: 0;
  background-color: var(--main-color, transparent);
  border-radius: 3px;
  box-shadow: ${styledByDisable(
    'none',
    '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);'
  )};
  color: ${styledByDisable('rgba(255, 255, 255, 0.56)', 'white')};
  cursor: ${styledByDisable('not-allowed', 'pointer')};
  user-select: none;
  transition: filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    filter: ${styledByDisable('none', 'brightness(90%)')};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 10px;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 42px;
  padding: 7px 15px;
  background: rgba(0, 0, 0, 0.05);
`;
