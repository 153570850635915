import { createStyles } from '@mui/styles';
import styled from 'styled-components';

export const styles = () =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    success: {
      color: '#15B371',
      backgroundColor: 'transparent',
    },
    failure: {
      color: '#F55656',
      backgroundColor: 'transparent',
    },
  });

export const CodeWrapper = styled.pre`
  white-space: pre-wrap;
`;

export const StyledCountryEmojiWrapper = styled.span`
  font-size: 0.8rem;
`;
